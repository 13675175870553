import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton	
} from "react-share";


import {
  FacebookIcon,
  WhatsappIcon,
  TwitterIcon
} from "react-share";

function BlogOne() {
	return (
		<div className="section section-basic">
	        <Container>

	        	<Row className="mt-5">
            		<Col md="6">
	          			<h3 className="title">Google Keynote (Google I/O 2019)</h3>
	          			<iframe title="iframe0" className="youtube-iframe" src="https://www.youtube.com/embed/TQSaPsKHPqs" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
		          			<span className="react-share__ShareButton">Share : </span>
		          			<FacebookShareButton url='https://www.webniter.com/'>
	          					<FacebookIcon size={32} round={true} />
	          				</FacebookShareButton>
	          				<TwitterShareButton url='https://www.webniter.com/'>
	          					<TwitterIcon size={32} round={true} />
	          				</TwitterShareButton>
	          				<WhatsappShareButton url='https://www.webniter.com/'>
	          					<WhatsappIcon size={32} round={true} />
	          				</WhatsappShareButton>
	          		</Col>
	          		<Col md="6">
	          			<h3 className="title">Google interns' first week</h3>
	          			<iframe title="iframe1" className="youtube-iframe" src="https://www.youtube.com/embed/9No-FiEInLA" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
	          			<span className="react-share__ShareButton">Share : </span>
	          			<FacebookShareButton url='https://www.webniter.com/'>
          					<FacebookIcon size={32} round={true} />
          				</FacebookShareButton>
          				<TwitterShareButton url='https://www.webniter.com/'>
          					<TwitterIcon size={32} round={true} />
          				</TwitterShareButton>
          				<WhatsappShareButton url='https://www.webniter.com/'>
          					<WhatsappIcon size={32} round={true} />
          				</WhatsappShareButton>
	          		</Col>
	          	</Row>

	          	<Row>
	          		<Col md="12"><h3 className="title">Introducing Google Gnome</h3></Col>
	          		<Col md="8">
	          			<div className="row">
							<div className="col-md-6">
								<div className="img-card-bg card">
									<div className="card-body">
										<div className="card-ico"><i className="fa fa-bolt fa-4x"></i></div>
										<h5 className="card-title">Quality Services</h5>
										<p className="card-text">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="img-card-bg card">
									<div className="card-body">
										<div className="card-ico"><i className="fa fa-flag fa-4x"></i></div>
										<h5 className="card-title">Customer Delight</h5>
										<p className="card-text">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="img-card-bg card">
									<div className="card-body">
										<div className="card-ico"><i className="fa fa-cog fa-4x"></i></div>
										<h5 className="card-title">Smart and Fast</h5>
										<p className="card-text">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="img-card-bg card">
									<div className="card-body">
										<div className="card-ico"><i className="fa fa-user fa-4x"></i></div>
										<h5 className="card-title">People Driven</h5>
										<p className="card-text">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
									</div>
								</div>
							</div>
						</div>
	          		</Col>
	          		<Col md="4">
	          			<img
		                	alt="..."
		                	src={require("assets/img/right-blog-img.jpg")}
		                	className="right-blog-img"
		                ></img>
	          		</Col>
	          	</Row>
	          	
      			<Row className="mt-5">
					<div className="col-md-12 mt-3 mb-3 text-center">
						<p className="theme-para">Read our blog</p>
						<h3 className="theme-title">Latest News</h3>
					</div>
					<div className="col-md-4">
						<div className="card">
							<img alt="..." src={require("assets/img/blog-1.jpg")} className="blog-thumb"/>
						</div>
						<h6 className="blog-cat"><span className="active"><i className="fa fa-tags"></i> Travel</span> <span><i className="fa fa-clock"></i> May 12, 2020</span>
						<FacebookShareButton url='https://www.webniter.com/'>
          					<FacebookIcon size={32} round={true} />
          				</FacebookShareButton>
          				<TwitterShareButton url='https://www.webniter.com/'>
          					<TwitterIcon size={32} round={true} />
          				</TwitterShareButton>
          				<WhatsappShareButton url='https://www.webniter.com/'>
          					<WhatsappIcon size={32} round={true} />
          				</WhatsappShareButton>
						</h6>
						<h3 className="blog-title">MUNICH – CITY THAT DESERVE MORE THAN A STOPOVER</h3>
					</div>

					<div className="col-md-4">
						<div className="card">
							<img alt="..." src={require("assets/img/blog-2.jpg")} className="blog-thumb"/>
						</div>
						<h6 className="blog-cat"><span className="active"><i className="fa fa-tags"></i> Travel</span> <span><i className="fa fa-clock"></i> May 12, 2020</span>
						<FacebookShareButton url='https://www.webniter.com/'>
          					<FacebookIcon size={32} round={true} />
          				</FacebookShareButton>
          				<TwitterShareButton url='https://www.webniter.com/'>
          					<TwitterIcon size={32} round={true} />
          				</TwitterShareButton>
          				<WhatsappShareButton url='https://www.webniter.com/'>
          					<WhatsappIcon size={32} round={true} />
          				</WhatsappShareButton>
						</h6>
						<h3 className="blog-title">21 THING TO KNOW BEFORE YOUR TRIP TO KOLKOTA</h3>
					</div>

					<div className="col-md-4">
						<div className="card">
							<img alt="..." src={require("assets/img/blog-3.jpg")} className="blog-thumb"/>
						</div>
						<h6 className="blog-cat"><span className="active"><i className="fa fa-tags"></i> Travel</span> <span><i className="fa fa-clock"></i> May 12, 2020</span>
						<FacebookShareButton url='https://www.webniter.com/'>
          					<FacebookIcon size={32} round={true} />
          				</FacebookShareButton>
          				<TwitterShareButton url='https://www.webniter.com/'>
          					<TwitterIcon size={32} round={true} />
          				</TwitterShareButton>
          				<WhatsappShareButton url='https://www.webniter.com/'>
          					<WhatsappIcon size={32} round={true} />
          				</WhatsappShareButton>
						</h6>
						<h3 className="blog-title">PINGVELLIR, ICELAND’S OLDEST NATIONAL PARK</h3>
					</div>
				</Row>
	        </Container>
        </div>
	);
}

export default BlogOne;